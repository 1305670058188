<ng-container *ngIf="!path">
  <button
    class="btn d-flex align-items-center"
    [ngClass]="{
      'btn-primary': type === 'primary',
      'btn-outline-primary': type === 'outline',
      'btn-link': type === 'link',
      'justify-content-center': centeredLabel === true
    }"
    [disabled]="disabled"
    (click)="clickHandler($event)"
    [type]="role"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="path">
  <a
    *ngIf="!path.includes('http')"
    class="btn d-flex align-items-center"
    [ngClass]="{
      'btn-primary': type === 'primary',
      'btn-outline-primary': type === 'outline',
      'btn-link': type === 'link',
      'justify-content-center': centeredLabel === true
    }"
    [routerLink]="path"
    [queryParams]="queryParams"
    (click)="trigger.emit()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
  <a
    *ngIf="path.includes('http')"
    class="btn d-flex align-items-center"
    [ngClass]="{
      'btn-primary': type === 'primary',
      'btn-outline-primary': type === 'outline',
      'btn-link': type === 'link',
      'justify-content-center': centeredLabel === true
    }"
    [href]="path"
    target="_blank"
    (click)="trigger.emit()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-template #content>
  <span>{{ label }}</span>
  <fa-icon class="ms-2" [icon]="['far', 'chevron-right']" *ngIf="withIcon"></fa-icon>
</ng-template>
