import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss'],
})
export class CustomButtonComponent {
  @Input() role: 'button' | 'submit' = 'button';
  @Input() label: string;
  @Input() path: string;
  @Input() queryParams: { section: string };
  @Input() disabled = false;
  @Input() type: 'primary' | 'link' | 'outline' = 'primary';
  @Input() centeredLabel = true;
  @Input() withIcon = true;

  @Output() trigger: EventEmitter<void> = new EventEmitter();

  clickHandler(event: MouseEvent): void {
    event.stopPropagation();
    this.trigger.emit();
  }
}
