import { Injectable } from '@angular/core';
import {
  ApiRestService,
  Product,
  ProductCatalogueRestService,
  ProductCategory,
  ProductSearch,
  SearchResult,
} from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductCatalogueLenovoBoostRestService extends ProductCatalogueRestService {
  constructor(protected override apiRestService: ApiRestService) {
    super(apiRestService, { environment });
  }

  override getCategories(): Observable<ProductCategory[]> {
    return this.apiRestService.get<ProductCategory[]>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/boost/catalogs/categories`
    );
  }

  override searchProducts(productSearch: ProductSearch): Observable<SearchResult<Product>> {
    return this.apiRestService.post<SearchResult<Product>>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/boost/catalogs/products/search`,
      productSearch
    );
  }
}
