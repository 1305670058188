import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(public authStore: AuthStoreService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.api.core.baseUrl) || request.url.includes(environment.api.core.bankingUrl)) {
      return this.authStore.getSingleUserSession$().pipe(
        switchMap((userSession) => {
          const token = userSession.getIdToken().getJwtToken();
          const headers: any = {
            Authorization: `Bearer ${token}`,
          };

          const impersonateUserId = this.authStore.getImpersonatedUserId();
          if (this.authStore.userSettings && impersonateUserId) {
            headers['admin-impersonate-user-id'] = impersonateUserId;
          }

          const tempRequest = request.clone({
            setHeaders: headers,
          });

          return next.handle(tempRequest);
        })
      );
    }

    return next.handle(request);
  }
}
