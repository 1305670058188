<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ titleDisplayKey! | translate }}</h4>
</div>
<div class="modal-body">
  <div
    *ngIf="messages && messages.length === 1"
    [innerHTML]="messages![0] | translate : parameters![0] | safeHtml"
  ></div>
  <ng-container *ngIf="messages && messages.length > 1">
    <div
      [ngClass]="{ 'mt-2': index > 0 }"
      *ngFor="let messageItem of messages; let index = index"
      [innerHTML]="messageItem | translate : parameters![index] | safeHtml"
    ></div>
  </ng-container>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" type="submit" (click)="activeModal.close('Save click')">
    {{ 'OK' | translate }}
  </button>
</div>
