import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Role } from 'src/app/core/enum/role';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@Injectable({
  providedIn: 'root',
})
export class MspGuard implements CanActivate {
  constructor(private router: Router, private userStore: UserStoreService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userStore.userRole$.pipe(
      map((role) => {
        if (role) {
          return role.name === Role.MSP;
        }
        this.router.navigate(['/']);
        return false;
      })
    );
  }
}
