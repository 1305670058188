<div class="d-flex flex-column justify-content-center wrapper">
  <div class="offcanvas-body d-flex flex-column">
    <h4>{{ 'Credits Summary' | translate }}</h4>

    <div class="mt-4 d-flex justify-content-between align-items-center">
      <h6>{{ 'Sales Transactions' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.salePoints | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Training and Certitifications' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.learnPoints | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Promotions' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.promoPoints | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Redeemed' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.redeemedPoints | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Expired' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.expiredPoints | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Total' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.availableBalance | number }}</div>
    </div>
  </div>
  <hr />
  <div class="offcanvas-body d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center">
      <h6>
        {{ 'Credits available to redeem until' | translate }}
        {{ (currentYearAccountSummary$ | async)?.expirationDate | date }}
      </h6>
      <div class="ms-3">{{ (accountSummary$ | async)?.availableBalance | number }}</div>
    </div>
  </div>
</div>
