<app-modal-open-padding-wrapper>
  <header #header [ngClass]="{ fixed: headerFixed }">
    <div class="w-100 h-100 logos-wrapper d-flex justify-content-center">
      <div class="h-100 logo">
        <div class="ms-3 ms-lg-4 h-100 d-flex align-items-center">
          <a [routerLink]="'/'">
            <img [src]="logoUrl" alt="Lenovo Boost 360" />
          </a>
        </div>
      </div>
      <div class="h-100 container">
        <div class="h-100 row">
          <div class="col-12 p-0 d-flex justify-content-start align-items-center">
            <div class="navbar" *ngIf="(authenticatedUser$ | async) !== null">
              <div class="h-100 flex-fill">
                <div class="m-0 w-100 h-100 row">
                  <div class="p-0 col-12">
                    <div class="h-100 d-none d-xl-block navbar-wrapper offset-margin" *ngIf="headerMenu">
                      <div class="h-100 lower-nav px-5 d-flex justify-content-start align-items-center">
                        <ng-container *ngFor="let item of headerMenu.items">
                          <ng-container *ngIf="item.items && item.items.length === 0">
                            <div
                              class="h-100 d-flex justify-content-center align-items-center navbar-item me-4"
                              routerLinkActive="active"
                            >
                              <a [routerLink]="item.url" *ngIf="!item.url.includes('http')">
                                <span>{{ item.label }}</span>
                              </a>
                              <a [href]="item.url" target="_blank" *ngIf="item.url.includes('http')">
                                <span>{{ item.label }}</span>
                              </a>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="item.items && item.items.length > 0">
                            <div
                              class="h-100 d-flex justify-content-center align-items-center navbar-item me-4 dropdown my-accounts"
                              ngbDropdown
                            >
                              <a
                                [routerLink]="item.url"
                                routerLinkActive="active"
                                class="dropdown-toggle"
                                data-toggle="dropdown"
                                *ngIf="!item.url.includes('http')"
                                ngbDropdownToggle
                                >{{ item.label }}</a
                              >
                              <a
                                [href]="item.url"
                                target="_blank"
                                class="dropdown-toggle"
                                data-toggle="dropdown"
                                *ngIf="item.url.includes('http')"
                                >{{ item.label }}</a
                              >
                              <ul class="dropdown-menu" ngbDropdownMenu>
                                <ng-container *ngFor="let subItem of item.items">
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      [routerLink]="subItem.url"
                                      *ngIf="!subItem.url.includes('http')"
                                      >{{ subItem.label }}</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      [href]="subItem.url"
                                      target="_blank"
                                      *ngIf="subItem.url.includes('http')"
                                      >{{ subItem.label }}</a
                                    >
                                  </li>
                                </ng-container>
                              </ul>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>

                    <div class="d-xl-none w-100 h-100 navbar-wrapper flex-row justify-content-end align-items-center">
                      <div class="d-flex justify-content-end align-items-center icons-nav">
                        <div class="mx-3">
                          <lib-notification-bell-dropdown
                            [iconType]="'light'"
                            [iconTransform]="'grow-3'"
                          ></lib-notification-bell-dropdown>
                        </div>

                        <div class="mx-3">
                          <a [routerLink]="'/reward-catalog/cart'">
                            <fa-icon [icon]="['fal', 'shopping-cart']" [transform]="'grow-2'"></fa-icon>
                          </a>
                        </div>
                        <app-profile-menu class="mx-3"></app-profile-menu>
                        <app-main-menu [headerMenu]="headerMenu" class="mx-3"></app-main-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(authenticatedUser$ | async) !== null">
        <div class="h-100 me-3 me-xxl-4 d-none d-xl-flex justify-content-end align-items-center icons-nav absolute">
          <div *ngIf="showLanguageSwitcher"
          class="me-3 me-xxl-4 d-lg-flex justify-content-end align-items-center icons-nav"
          [ngClass]="{ absolute: (authenticatedUser$ | async) === null }"
        >
          <app-language-switcher></app-language-switcher>
        </div>
          <div class="me-3">
            <lib-switch-back-to-admin-button></lib-switch-back-to-admin-button>
          </div>

          <div class="mx-2 mx-xxl-3">
            <lib-notification-bell-dropdown
              [iconType]="'light'"
              [iconTransform]="'grow-4'"
            ></lib-notification-bell-dropdown>
          </div>

          <div class="mx-3">
            <a [routerLink]="'/reward-catalog/cart'">
              <fa-icon [icon]="['fal', 'shopping-cart']" transform="grow-3"></fa-icon>
            </a>
          </div>
          <app-profile-menu class="ms-2 ms-xxl-3"></app-profile-menu>
        </div>
      </div>
    </div>
  </header>
</app-modal-open-padding-wrapper>
