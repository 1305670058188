import { Injectable } from '@angular/core';
import { ContentGqlService } from '@motivforce/mx-library-angular';
import { BehaviorSubject } from 'rxjs';

import { Banner } from '../model/core/banner';

@Injectable({
  providedIn: 'root',
})
export class BannerStoreService {
  private readonly _banner = new BehaviorSubject<Banner | null>(null);
  readonly banner$ = this._banner.asObservable();

  constructor(private contentGql: ContentGqlService) {}

  get banner(): Banner | null {
    return this._banner.getValue();
  }

  getBanner(): void {
    if (this.banner) {
      return;
    }
    const siteUrl = new URL(window.location.href);
    const tncPath = `${siteUrl.origin}/banner-header`;

    this.contentGql.getContentByUrlPath(tncPath, 'en-US', true).subscribe((content) => {
      const bannerField = content.fields.find((f: any) => f.name === 'Banner');
      const tabletBannerField = content.fields.find((f: any) => f.name === 'Tablet Banner');
      const mobileBannerField = content.fields.find((f: any) => f.name === 'Mobile Banner');

      const imageUrl = bannerField!.value.url;
      const tabletImageUrl = tabletBannerField!.value.url;
      const mobileImageUrl = mobileBannerField!.value.url;

      this._banner.next({ imageUrl, tabletImageUrl, mobileImageUrl });
    });
  }
}
