<div class="modal-header">
  <h1 class="modal-title" id="modal-basic-title">{{ 'Important' | translate }}</h1>
</div>
<div class="modal-body">
  <div class="prompt">
    {{ 'Please make sure you add you bank details in order to process Lenovo 360 Boost payments' | translate }}
  </div>
  <br />
  <div>
    <a class="bank-details-action" (click)="onClick()">{{ 'Click here' | translate }}</a>
    {{ 'to fill the form' | translate }}.
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="activeModal.close()">{{ 'Close' | translate }}</button>
</div>
