import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFrCa from '@angular/common/locales/fr-CA';
import localeJa from '@angular/common/locales/ja';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { QueryParamsUrlSerializer } from '@motivforce/mx-library-angular';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

// TODO MB: Ideally we should be able to dynamically import the locales, but I'm not convinced it worked efficiently enough
// https://stackoverflow.com/a/61060895

function initalizeApp() {
  const locales = [localeEn, localeEs, localeFrCa, localeJa, localePt];
  return async () =>
    new Promise((resolve, reject) => {
      try {
        locales.forEach((locale) => registerLocaleData(locale));
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    AuthenticationModule,
  ],
  providers: [
    { provide: UrlSerializer, useClass: QueryParamsUrlSerializer },
    { provide: APP_INITIALIZER, useFactory: initalizeApp, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
