import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, TranslateResolver } from '@motivforce/mx-library-angular';

import { MspGuard } from './core/guard/msp.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
    canActivate: [],
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my-account',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('My Account') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./my-account/my-account.module').then((m) => m.MyAccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sales-promotions',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Sales Promotions') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./sales-promotions/sales-promotions.module').then((m) => m.SalesPromotionsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'milestones',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Milestones') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./milestones/milestones.module').then((m) => m.MilestonesModule),
    canActivate: [AuthGuard, MspGuard],
  },
  {
    path: 'terms-and-conditions',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Terms and Conditions') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
    canActivate: [],
  },
  {
    path: 'program-overview',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Program Overview') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./program-overview/program-overview.module').then((m) => m.ProgramOverviewModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'training-and-certifications',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Training & Certifications') },
    resolve: { translate: TranslateResolver },
    loadChildren: () =>
      import('./training-and-certifications/training-and-certifications.module').then(
        (m) => m.TrainingAndCertificationsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'submit-an-activity',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Submit an Activity') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./submit-an-activity/submit-an-activity.module').then((m) => m.SubmitAnActivityModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'reward-catalog',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Reward Catalog') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./product-catalogue/product-catalogue.module').then((m) => m.ProductCatalogueModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
