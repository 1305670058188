<div class="wrapper">
  <lib-generic-list
    [dataProvider]="dataProvider"
    [totalCount]="dataProvider.length"
    [pageSize]="dataProvider.length"
    [columnsProvider]="columnsProvider"
    [noResultsMessageDisplayKey]="noResultsMessageDisplayKey"
    [showPagination]="false"
    [tableStriped]="false"
  >
    <ng-container *libGenericListCustomItemRenderer="'imageUrl'; let row">
      <a class="text-decoration-none" [routerLink]="'/reward-catalog/product/' + row.productId">
        <div class="cart-list-img">
          <img *ngIf="row.imageUrl" [src]="row.imageUrl" />
        </div>
      </a>
    </ng-container>
    <ng-container *libGenericListCustomItemRenderer="'name'; let row">
      <div class="d-flex flex-column justify-content-between">
        <div class="d-flex flex-column">
          <a class="text-decoration-none" [routerLink]="'/reward-catalog/product/' + row.productId">
            <div class="title">{{ row.name }}</div>
          </a>
          <div class="mb-1">
            {{ row.productId }}
          </div>
          <p class="mb-1 description">
            {{ row.shortDescription }}
          </p>
        </div>
        <div>
          <button *ngIf="editable" class="btn btn-link remove" type="button" (click)="itemRemove.emit(row.productId)">
            {{ 'Remove' | translate }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="editable">
      <ng-container *libGenericListCustomItemRenderer="'quantity'; let row">
        <div class="d-flex quantity">
          <button class="p-2 btn btn-primary" (click)="decrementQuantity(row)">
            <fa-icon [icon]="['far', 'minus']"></fa-icon>
          </button>
          <div class="h-100 w-100 d-flex justify-content-center align-items-center">
            {{ row.quantity }}
          </div>
          <button class="p-2 btn btn-primary" (click)="incrementQuantity(row)">
            <fa-icon [icon]="['far', 'plus']"></fa-icon>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </lib-generic-list>
</div>
