<div class="footer-top-nav" *ngIf="loggedIn">
  <div class="container">
    <div class="row pt-1">
      <div class="col-12 col-sm-6 col-lg-4 footer-menu-column">
        <div class="pt-5 py-sm-5 d-flex flex-column" *ngIf="footerMenuHelp">
          <h5>{{ footerMenuHelp.name }}</h5>
          <div class="mt-2" *ngFor="let item of footerMenuHelp.items">
            <a [routerLink]="item.url" *ngIf="!item.url.includes('http')">
              <span>{{ item.label }}</span>
            </a>
            <a [href]="item.url" target="_blank" *ngIf="item.url.includes('http')">
              <span>{{ item.label }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4 footer-menu-column">
        <div class="py-5 d-flex flex-column" *ngIf="footerMenuAbout">
          <h5>{{ footerMenuAbout.name }}</h5>
          <div class="mt-2" *ngFor="let item of footerMenuAbout.items">
            <a [routerLink]="item.url" *ngIf="!item.url.includes('http')">
              <span>{{ item.label }}</span>
            </a>
            <a [href]="item.url" target="_blank" *ngIf="item.url.includes('http')">
              <span>{{ item.label }}</span>
            </a>
          </div>
          <div class="mt-2">
            <a class="me-2" href="https://x.com/Lenovo" target="_blank"
              ><fa-icon [icon]="['fab', 'x-twitter']"></fa-icon
            ></a>
            <a class="me-2" href="https://www.youtube.com/lenovo" target="_blank"
              ><fa-icon [icon]="['fab', 'youtube']"></fa-icon
            ></a>
            <a class="me-2" href="https://www.facebook.com/lenovo/" target="_blank"
              ><fa-icon [icon]="['fab', 'facebook']"></fa-icon
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
