import { Component, Input } from '@angular/core';
import { NavMenu } from '@motivforce/mx-library-angular';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent {
  @Input()
  headerMenu: NavMenu;

  constructor(private offcanvasService: NgbOffcanvas) {}

  open(content: any) {
    this.offcanvasService.open(content, {
      position: 'end',
      ariaLabelledBy: 'offcanvas-basic-title',
      panelClass: 'main-menu-panel',
    });
  }
}
