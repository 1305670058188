import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import {
  ApiAwsService,
  ApiGqlService,
  ApiRestService,
  DialogNgbService,
  MxLoggerService,
  AuthStoreService,
  ApiError,
  NotificationStoreService,
} from '@motivforce/mx-library-angular';
import { TranslateService } from '@ngx-translate/core';
import { IsLoadingService } from '@service-work/is-loading';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrimeNGConfig } from 'primeng-lts/api';
import { debounceTime, filter, take } from 'rxjs/operators';

import { PromotionStoreService } from './core/store/promotion-store.service';
import { UserStoreService } from './core/store/user-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private isLoadingService: IsLoadingService,
    private mxLogger: MxLoggerService,
    private dialog: DialogNgbService,
    private apiRestService: ApiRestService,
    private apiGqlService: ApiGqlService,
    private apiAwsService: ApiAwsService,
    private renderer: Renderer2,
    private authStore: AuthStoreService,
    private userStore: UserStoreService,
    private notificationStore: NotificationStoreService,
    private spinner: NgxSpinnerService,
    private promotionStore: PromotionStoreService,
    private primengConfig: PrimeNGConfig,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.setDefaultLang('en-US');

    this.isLoadingService
      .isLoading$()
      .pipe(debounceTime(200))
      .subscribe((isLoading) => {
        if (isLoading) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
      });

    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          this.isLoadingService
            .isLoading$()
            .pipe(
              filter((value) => value),
              take(1)
            )
            .subscribe(() => {
              this.renderer.addClass(document.body, 'scroll-disabled');
            });
          return;
        }

        this.isLoadingService.remove();
        this.isLoadingService
          .isLoading$()
          .pipe(
            filter((value) => !value),
            take(1)
          )
          .subscribe(() => {
            this.renderer.removeClass(document.body, 'scroll-disabled');
          });
      });

    this.isLoadingService
      .isLoading$()
      .pipe(filter((value) => !value))
      .subscribe(() => {
        this.renderer.removeClass(document.body, 'scroll-disabled');
      });

    this.authStore.authenticatedUser$.pipe(filter(Boolean), take(1)).subscribe((authenticatedUser) => {
      this.mxLogger.trace('AppComponent', 'ngOnInit() authenticatedUser=', authenticatedUser);
    });

    this.authStore.userSettings$.pipe(filter(Boolean)).subscribe((userSettings) => {
      this.mxLogger.trace('AppComponent', 'ngOnInit() userSettings=', userSettings);

      if (userSettings.language) {
        this.translate.use(userSettings.language);
      }

      this.userStore.getAccountSummary();
      this.userStore.getYearAccountSummaries();
      this.userStore.getProfile();
      // For OEM/MSP user
      this.userStore.setUserRole(userSettings);

      this.userStore.getCurrency();
    });

    this.authStore.isRegularUser$.pipe(filter(Boolean)).subscribe(() => {
      this.notificationStore.getBellNotifications();
      this.userStore.getPerformance();
    });

    this.authStore.passwordChanged$.pipe(filter(Boolean)).subscribe(() => {
      this.dialog.openNotification(['You have updated your password successfully.'], '');
    });

    this.apiRestService.error$.subscribe((apiError) => this.onApiError(apiError));
    this.apiGqlService.error$.subscribe((apiError) => this.onApiError(apiError));
    this.apiAwsService.error$
      .pipe(filter((apiError) => apiError.name !== 'authentication' && apiError.name !== 'authorization'))
      .subscribe((apiError) => this.onApiError(apiError));
    this.primengConfig.ripple = true;
  }

  onApiError(apiError: ApiError): void {
    this.mxLogger.debug('AppComponent', 'onApiError() apiError=', apiError);

    if (apiError.name === 'authentication' || apiError.name === 'authorization') {
      this.authStore.clearSession();
    } else if (apiError.name === 'server') {
      this.dialog.openError(
        apiError.messages && apiError.messages.length > 0 ? apiError.messages : [apiError.message],
        'A problem occurred'
      );
    } else if (apiError.name === 'warning') {
      this.dialog.openWarning([apiError.message], 'Important');
    } else if (apiError.name === 'validation') {
      this.dialog.openValidation(apiError.messages!, 'Important');
    } else {
      this.dialog.openError(apiError.message ? [apiError.message] : apiError.messages!, 'Important');
    }
  }
}
