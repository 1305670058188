/* eslint-disable */
const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_MK2zZyHlO',
  aws_user_pools_web_client_id: '1bgnne16f5nrosi30qvvmvki9j',
  oauth: {
    domain: 'dev-lenovo.auth.eu-west-2.amazoncognito.com',
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'],
    redirectSignIn: 'https://boost-dev.motivforceit.com/auth/redirect/',
    redirectSignOut: 'https://boost-dev.motivforceit.com/auth/logout/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['GOOGLE'],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_UPPERCASE', 'REQUIRES_NUMBERS', 'REQUIRES_SYMBOLS'],
  },
  aws_cognito_verification_mechanisms: [],
};

export default awsmobile;
