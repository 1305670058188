import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faBell, faChevronRight, faChevronUp, faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
  BannerModule,
  BreadcrumbsModule,
  FormModule,
  GenericCarouselModule,
  GenericListModule,
  PipesModule,
} from '@motivforce/mx-library-angular';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AccentRowComponent } from './components/accent-row/accent-row.component';
import { AccountSummaryWidgetComponent } from './components/account-summary-widget/account-summary-widget.component';
import { ActionBoxComponent } from './components/action-box/action-box.component';
import { BackToLinkComponent } from './components/back-to-link/back-to-link.component';
import { BackToTopButtonComponent } from './components/back-to-top-button/back-to-top-button.component';
import { BankDetailsFormComponent } from './components/bank-details-form/bank-details-form.component';
import { BannerComponent } from './components/banner/banner.component';
import { CarouselSliderComponent } from './components/carousel-slider/carousel-slider.component';
import { CartWidgetComponent } from './components/cart-widget/cart-widget.component';
import { CompanyPerformanceComponent } from './components/company-performance/company-performance.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { CustomListComponent } from './components/custom-list/custom-list.component';
import { CustomProductListComponent } from './components/custom-product-list/custom-product-list.component';
import { CustomSelectFormControlComponent } from './components/custom-select-form-control/custom-select-form-control.component';
import { CustomTransactionsListComponent } from './components/custom-transactions-list/custom-transactions-list.component';
import { DefaultMainLayoutComponent } from './components/default-main-layout/default-main-layout.component';
import { ImageSliderPuzzleComponent } from './components/image-slider-puzzle/image-slider-puzzle.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LogoComponent } from './components/logo/logo.component';
import { ModalOpenPaddingWrapperComponent } from './components/modal-open-padding-wrapper/modal-open-padding-wrapper.component';
import { PromotionAnimatedButtonComponent } from './components/promotion-animated-button/promotion-animated-button.component';
import { PromotionBoxComponent } from './components/promotion-box/promotion-box.component';
import { WidgetMainLayoutComponent } from './components/widget-main-layout/widget-main-layout.component';
import { MinutesSecondsPipe } from './pipes/minutes-seconds.pipe';
import { ShowPointsIconPipe } from './pipes/show-points-icon.pipe';
import { ShowRevenueIconPipe } from './pipes/show-revenue-icon.pipe';

@NgModule({
  declarations: [
    LogoComponent,
    LoadingIndicatorComponent,
    AccountSummaryWidgetComponent,
    MinutesSecondsPipe,
    ModalOpenPaddingWrapperComponent,
    ImageSliderPuzzleComponent,
    PromotionAnimatedButtonComponent,
    DefaultMainLayoutComponent,
    WidgetMainLayoutComponent,
    CarouselSliderComponent,
    PromotionBoxComponent,
    ActionBoxComponent,
    CartWidgetComponent,
    CustomListComponent,
    CustomProductListComponent,
    AccentRowComponent,
    BackToLinkComponent,
    CustomSelectFormControlComponent,
    BackToTopButtonComponent,
    ShowRevenueIconPipe,
    ShowPointsIconPipe,
    CustomTransactionsListComponent,
    BannerComponent,
    CompanyPerformanceComponent,
    BankDetailsFormComponent,
    CustomButtonComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgxSpinnerModule,
    NgbDropdownModule,
    GenericCarouselModule,
    BannerModule,
    TranslateModule,
    BreadcrumbsModule,
    GenericListModule,
    NgbCarouselModule,
    PipesModule,
    FormModule,
  ],
  exports: [
    LogoComponent,
    LoadingIndicatorComponent,
    AccountSummaryWidgetComponent,
    MinutesSecondsPipe,
    ModalOpenPaddingWrapperComponent,
    ImageSliderPuzzleComponent,
    PromotionAnimatedButtonComponent,
    DefaultMainLayoutComponent,
    CarouselSliderComponent,
    CartWidgetComponent,
    PromotionBoxComponent,
    ActionBoxComponent,
    CartWidgetComponent,
    CustomListComponent,
    CustomProductListComponent,
    AccentRowComponent,
    BackToLinkComponent,
    CustomSelectFormControlComponent,
    BackToTopButtonComponent,
    ShowRevenueIconPipe,
    ShowPointsIconPipe,
    CustomTransactionsListComponent,
    BannerComponent,
    BankDetailsFormComponent,
    CustomButtonComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(private iconlibrary: FaIconLibrary) {
    this.iconlibrary.addIcons(faBell, faChevronRight, faMinus, faPlus, faXmark, faChevronUp);
  }
}
