import { Component } from '@angular/core';
import { LanguageStoreService } from 'src/app/core/store/language-store.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent {
  constructor(private languageStore: LanguageStoreService) {}

  setLanguage(language: string): void {
    this.languageStore.setLanguage(language);
  }
}
