import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractDialogComponent } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bank-details-dialog',
  templateUrl: './bank-details-dialog.component.html',
  styleUrls: ['./bank-details-dialog.component.scss'],
})
export class BankDetailsDialogComponent extends AbstractDialogComponent {
  constructor(public activeModal: NgbActiveModal, private router: Router) {
    super();
  }

  onClick(): void {
    this.activeModal.close();
    this.router.navigate(['/my-account'], { queryParams: { tab: 6 } });
  }
}
