<div class="h-100 container">
  <div class="h-100 row">
    <div class="col-12">
      <div class="h-100 d-flex flex-column justify-content-center">
        <h1>{{ 'Not allowed' | translate | uppercase }}</h1>
        <h3 class="mt-4">{{ 'You are not allowed to enter the site. Please, sign in first.' | translate }}</h3>
      </div>
    </div>
  </div>
</div>
