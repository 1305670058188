import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentStoreService, AuthModule, BannerModule } from '@motivforce/mx-library-angular';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { ForgotPasswordContentTemplateComponent } from './components/forgot-password-content-template/forgot-password-content-template.component';
import { LoginContainerComponent } from './components/login-container/login-container.component';
import { LoginRedirectContainerComponent } from './components/login-redirect-container/login-redirect-container.component';
import { NotAllowedContainerComponent } from './components/not-allowed-container/not-allowed-container.component';
import { SetPasswordContentTemplateComponent } from './components/set-password-content-template/set-password-content-template.component';

@NgModule({
  declarations: [
    ForgotPasswordContentTemplateComponent,
    SetPasswordContentTemplateComponent,
    NotAllowedContainerComponent,
    LoginContainerComponent,
    LoginRedirectContainerComponent,
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    BannerModule,
  ],
})
export class AuthenticationModule {
  constructor(contentStore: ContentStoreService) {
    contentStore.registerTemplate('forgotPasswordTemplate', ForgotPasswordContentTemplateComponent);
    contentStore.registerTemplate('setPasswordTemplate', SetPasswordContentTemplateComponent);
    contentStore.registerTemplate('loginTemplate', LoginContainerComponent);
  }
}
