import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ContentStoreService, MxLoggerService, AuthStoreService, NavMenu } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('header', { static: true }) headerElement: ElementRef<HTMLElement>;

  logoUrl = 'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/lenovo-boost-360.png';
  headerFixed = environment.headerFixed;

  authenticatedUser$ = this.authStore.authenticatedUser$.pipe(untilDestroyed(this));

  headerMenu: NavMenu;

  totalSales: number;
  totalRebate: number;
  quarter: string;
  currencySymbol: string;

  constructor(
    private authStore: AuthStoreService,
    private renderer: Renderer2,
    private contentStore: ContentStoreService,
    private mxLogger: MxLoggerService
  ) {}

  ngOnInit(): void {
    this.contentStore.headerMenu$.pipe(filter(Boolean)).subscribe((headerMenu) => {
      this.mxLogger.debug('HeaderComponent', 'ngOnInit() headerMenu=', headerMenu);

      this.headerMenu = headerMenu;
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (environment.headerFixed) {
      if (window.scrollY > 0) {
        this.renderer.addClass(this.headerElement.nativeElement, 'scrolled');
      } else {
        this.renderer.removeClass(this.headerElement.nativeElement, 'scrolled');
      }
    }
  }
}
