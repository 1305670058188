<div class="wrapper" [ngStyle]="{ 'min-height': widgetsHeight ? widgetsHeight + 'px' : 'unset' }">
  <app-banner></app-banner>
  <div class="d-flex flex-column">
    <div class="container">
      <div class="mx-3 mb-5 row">
        <div class="col-12">
          <lib-breadcrumbs [delimiter]="'>'"></lib-breadcrumbs>
        </div>
      </div>
      <div class="mx-3 mb-5 row" *ngIf="showTitle">
        <div class="col-12" *ngIf="showSubmitActivityBanner">
          <div class="mb-3 h-100 w-100 submit-activity-wrapper">
            <img class="d-none d-lg-block" [src]="submitActivityBanner" />
            <img class="d-none d-md-block d-lg-none" [src]="submitActivityBannerTablet" />
            <img class="d-block d-md-none" [src]="submitActivityBannerMobile" />
            <a
              class="btn btn-transparent d-flex justify-content-center align-items-center"
              [routerLink]="'/submit-an-activity'"
            >
              <span>
                {{ 'Find out more' | translate }}
              </span>
              <fa-icon class="ms-1" [icon]="['far', 'chevron-right']"></fa-icon>
            </a>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex flex-column flex-lg-row justify-content-between">
            <div class="title" [innerHtml]="banner?.title"></div>
            <app-custom-button
              path="/support/faq"
              [queryParams]="{ section: 'redeem-credits-faq' }"
              [label]="'FAQ' | translate"
              *ngIf="showSubmitActivityBanner"
            ></app-custom-button>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100 mb-5 content-wrapper">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="pt-3 side-widgets" #sideWidgets *ngIf="showSideWidgets">
    <ng-content select="[sideWidgets]"></ng-content>
  </div>

  <app-back-to-top-button></app-back-to-top-button>
</div>
