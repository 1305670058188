import { Component } from '@angular/core';
import { filter } from 'rxjs';
import { UserPartnerType } from 'src/app/core/enum/user-partner-type.enum';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@Component({
  selector: 'app-company-performance',
  templateUrl: './company-performance.component.html',
  styleUrls: ['./company-performance.component.scss'],
})
export class CompanyPerformanceComponent {
  UserPartnerType = UserPartnerType;

  performance$ = this.userStore.performance$.pipe(filter(Boolean));

  constructor(private userStore: UserStoreService) {}
}
