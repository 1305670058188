import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { take } from 'rxjs/operators';
import { PaymentsDetails } from 'src/app/core/model/lenovo/payments-details';
import { UserStoreService } from 'src/app/core/store/user-store.service';

import { FieldDefinition } from '../../../core/model/core/field-definition';

@UntilDestroy()
@Component({
  selector: 'app-bank-details-form',
  templateUrl: './bank-details-form.component.html',
  styleUrls: ['./bank-details-form.component.scss'],
})
export class BankDetailsFormComponent implements OnInit {
  @Input() readonly = false;

  details: PaymentsDetails;
  formFields: FieldDefinition[];

  form: UntypedFormGroup;

  formIsDisabled = true;

  customLengthFields = ['Country', 'State', 'City', 'Postcode', 'Beneficiary City', 'Beneficiary Country'];

  constructor(
    private userStore: UserStoreService,
    private formBuilder: UntypedFormBuilder,
    private authStore: AuthStoreService
  ) {}

  ngOnInit(): void {
    this.userStore.paymentsDetailsResult$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((paymentsDetails) => {
      this.details = paymentsDetails;
      this.formIsDisabled = true;

      this.formFields = [];
      this.details.fields.forEach((fieldGroup) => {
        this.formFields = [...this.formFields, ...fieldGroup.fields];
      });

      this.form = this.formBuilder.group(
        this.formFields.reduce((formFields: { [x: string]: any }, curr) => {
          const validators = [];

          if (!curr.skipClientSideValidation) {
            if (curr.isRequired) validators.push(Validators.required);
            if (curr.validationRegEx) validators.push(Validators.pattern(curr.validationRegEx));
          }

          formFields[this.toCamelCase(curr.fieldName)] = new FormControl(
            { value: curr.value || '', disabled: !curr.isEditable || this.formIsDisabled },
            validators
          );

          if (curr.options) {
            curr.filteredOptions = [...curr.options];
          }

          return formFields;
        }, {})
      );

      this.formFields.forEach((curr) => {
        if (curr.dependsOn) {
          const controlToDependOn = this.toCamelCase(curr.dependsOn);
          this.form.get(controlToDependOn)?.valueChanges.subscribe((value) => {
            curr.filteredOptions = curr.options.filter((option) => option.group === value);
          });
        }
      });

      this.form.patchValue(this.details);

      this.preSelectUserCountry();
    });

    this.authStore.isRegularUser$.pipe(untilDestroyed(this), filter(Boolean)).subscribe(() => {
      this.userStore.getPaymentsDetails();
    });
  }

  toCamelCase(s: string): string {
    if (s === s.toUpperCase()) {
      return s.toLowerCase();
    }
    return s.charAt(0).toLowerCase() + s.slice(1);
  }

  enableForm(): void {
    this.formIsDisabled = false;
    this.form.enable();
    this.form.get('country')?.disable();
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.userStore.updatePaymentDetails({ ...this.form.getRawValue() } as PaymentsDetails);
    }
  }

  preSelectUserCountry(): void {
    this.authStore.userSettings$.pipe(untilDestroyed(this), filter(Boolean), take(1)).subscribe((userSettings) => {
      if (userSettings.user != null) {
        const { country } = userSettings;
        if (country) {
          this.form.get('country')?.patchValue(country.alpha2Code);
        }
      }
    });
  }
}
