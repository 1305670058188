import { Component } from '@angular/core';
import { AuthStoreService, SiteLanguageStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent {
  siteLanguage: string;
  userSettingsLanguage: string;

  constructor(
    private siteLanguageStore: SiteLanguageStoreService,
    private translate: TranslateService,
    private authStore: AuthStoreService
  ) {}

  ngOnInit(): void {
    this.siteLanguageStore.language$.pipe(untilDestroyed(this)).subscribe((language) => {
      if (language) {
        this.translate.use(language);
        this.siteLanguage = language;
      }
    });

    this.authStore.userSettings$.pipe(untilDestroyed(this)).subscribe((userSettings) => {
      if (userSettings?.language) {
        this.userSettingsLanguage = userSettings.language;
        if (!this.siteLanguage) {
          this.translate.use(userSettings.language);
        }
      }
    });
  }

  setLanguage(language: string): void {
    this.siteLanguageStore.language = language;
  }
}
