<div class="row mx-3 my-3" *ngIf="!readonly">
  <div class="col-12">
    <div>{{ 'Add or update your bank account details.' | translate }}</div>
  </div>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="details">
  <div class="section">
    <div *ngIf="!readonly">
      <button type="button" class="btn btn-primary" (click)="enableForm()">{{ 'Add/Edit Details' | translate }}</button>
    </div>

    <div class="py-3 d-flex flex-wrap justify-content-between" *ngFor="let fieldGroup of details.fields">
      <label *ngIf="fieldGroup.label">{{ fieldGroup.label | translate }}</label>
      <div *ngFor="let field of fieldGroup.fields" [ngStyle]="{ width: field.width ? '49%' : '100%' }">
        <div class="form-group d-flex flex-column align-items-start mb-3">
          <label [for]="toCamelCase(field.fieldName)" class="pt-2">{{ field.labelName | translate }}</label>
          <div class="w-100">
            <input
              *ngIf="!field.options"
              type="text"
              [id]="toCamelCase(field.fieldName)"
              class="form-control"
              [formControlName]="toCamelCase(field.fieldName)"
            />
            <app-custom-select-form-control
              *ngIf="field.options"
              [dataProvider]="field.filteredOptions"
              [labelField]="'name' | translate"
              [valueField]="'id'"
              [id]="toCamelCase(field.fieldName)"
              [formControlName]="toCamelCase(field.fieldName)"
              [placeholder]="'Select' | translate"
              class="w-100"
            ></app-custom-select-form-control>
            <div *ngIf="form.get(toCamelCase(field.fieldName))?.errors?.['required']" class="text-danger">
              {{ 'Required Field' | translate }}
            </div>
            <div *ngIf="form.get(toCamelCase(field.fieldName))?.errors?.['pattern']" class="text-danger">
              {{ field.validationRegExMessage | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-primary" *ngIf="!formIsDisabled" [disabled]="!form.valid">
      {{ 'Save Details' | translate }}
    </button>
  </div>
</form>
