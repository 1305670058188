import { Injectable } from '@angular/core';
import { AccountSummary, ApiRestService, ReferenceData, SearchResult } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { CommunicationsPreference } from '../../model/core/communications-preference';
import { CreditsSummary } from '../../model/core/credits-summary';
import { Currency } from '../../model/core/currency';
import { Profile } from '../../model/core/profile';
import { Redemption } from '../../model/core/redemption';
import { RedemptionSearch } from '../../model/core/redemption-search';
import { SetPassword } from '../../model/core/set-password';
import { Status } from '../../model/core/status';
import { SubmittedActivity } from '../../model/core/submitted-activity';
import { SubmittedActivitySearch } from '../../model/core/submitted-activity-search';
import { Training } from '../../model/core/training';
import { TrainingSearch } from '../../model/core/training-search';
import { Transaction } from '../../model/core/transaction';
import { TransactionSearch } from '../../model/core/transaction-search';
import { Activity } from '../../model/lenovo/activity';
import { ActivityType } from '../../model/lenovo/activity-type';
import { LenovoProfile } from '../../model/lenovo/lenovo-profile';
import { PaymentsDetails } from '../../model/lenovo/payments-details';
import { TrainingDetails } from '../../model/lenovo/training-details';
import { TrainingVendor } from '../../model/lenovo/training-vendor';
import { UserPerformance } from '../../model/lenovo/user-performance';

@Injectable({
  providedIn: 'root',
})
export class UserRestService {
  constructor(private apiRest: ApiRestService) {}

  getAccountSummary(): Observable<AccountSummary> {
    return this.apiRest.get<AccountSummary>(`${environment.api.core.baseUrl}/members/users/account-summary`);
  }

  getYearAccountSummaries(): Observable<AccountSummary[]> {
    return this.apiRest.get<AccountSummary[]>(`${environment.api.core.baseUrl}/members/users/points/year-summaries`);
  }

  getProfile(): Observable<Profile> {
    return this.apiRest.get<Profile>(`${environment.api.core.baseUrl}/members/users/profile`);
  }

  getLenovoProfile(): Observable<LenovoProfile> {
    return this.apiRest.get<LenovoProfile>(`${environment.api.core.baseUrl}/members/clients/lenovo/users/profile`);
  }

  updateRole(profileRoleName: string): Observable<any> {
    return this.apiRest.post<any>(`${environment.api.core.baseUrl}/members/clients/lenovo/users/role`, {
      profileRoleName,
    });
  }

  getTransactionTypes(): Observable<ReferenceData[]> {
    return this.apiRest.get<ReferenceData[]>(`${environment.api.core.baseUrl}/members/transactions/types`);
  }

  searchPromotions(searchCriteria: TransactionSearch): Observable<SearchResult<Transaction>> {
    return this.apiRest.post<SearchResult<Transaction>>(
      `${environment.api.core.baseUrl}/members/users/promotion-transactions`,
      searchCriteria
    );
  }

  getCommunicationPreferences(): Observable<any> {
    return this.apiRest.get(
      `${environment.api.core.baseUrl}/members/clients/lenovoboost/profile/communications-preferences`
    );
  }

  updateCommunicationPreferences(form: CommunicationsPreference): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/profile/send-email`, form);
  }

  acceptTermsConditions(): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/terms-and-conditions/accept`, null);
  }

  setPassword(password: SetPassword) {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/set-password`, password);
  }

  createCheckoutSession(form: any): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/payments/create-checkout-session`, form);
  }

  getPerformance(): Observable<UserPerformance> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/boost/performance/`);
  }

  getTrainingVendors(): Observable<TrainingVendor[]> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/boost/trainings/vendors`);
  }

  submitTrainingPresignedUrl(fileName: string, fileContentType: string): Observable<any> {
    return this.apiRest.post<any>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/boost/trainings/presigned-url`,
      {
        fileName,
        fileContentType,
      }
    );
  }

  uploadTraining(file: File, presignedUrl: string): Observable<any> {
    return this.apiRest.put<any>(presignedUrl, file);
  }

  sendTrainingDetails(trainingDetails: TrainingDetails): Observable<any> {
    return this.apiRest.post<any>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/boost/trainings`,
      trainingDetails
    );
  }

  getTrainingDetails(id: number): Observable<TrainingDetails> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/boost/trainings/${id}`);
  }

  getActivityTypes(): Observable<ActivityType[]> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/boost/activities/activity-types`);
  }

  getActivity(id: number): Observable<Activity> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/boost/activities/${id}`);
  }

  submitActivity(activity: Activity): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/clients/lenovo/boost/activities`, activity);
  }

  submitActivityPresignedUrl(fileName: string, fileContentType: string, type: string): Observable<any> {
    return this.apiRest.post<any>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/boost/activities/presigned-url`,
      {
        fileName,
        fileContentType,
        activityFileType: type,
      }
    );
  }

  getPaymentsDetails(): Observable<PaymentsDetails> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/boost/activities/payments/details`);
  }

  updatePaymentsDetails(payment: PaymentsDetails): Observable<any> {
    return this.apiRest.post(
      `${environment.api.core.baseUrl}/members/clients/lenovo/boost/activities/payments/details`,
      payment
    );
  }

  searchTransactions(searchCriteria: TransactionSearch): Observable<SearchResult<Transaction>> {
    return this.apiRest.post<SearchResult<Transaction>>(
      `${environment.api.core.baseUrl}/members/transactions/search`,
      searchCriteria
    );
  }

  searchRedemptions(searchCriteria: RedemptionSearch): Observable<SearchResult<Redemption>> {
    return this.apiRest.post<SearchResult<Redemption>>(
      `${environment.api.core.baseUrl}/members/redemptions/search`,
      searchCriteria
    );
  }

  searchTrainings(searchCriteria: TrainingSearch): Observable<SearchResult<Training>> {
    return this.apiRest.post<SearchResult<Training>>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/boost/trainings/search`,
      searchCriteria
    );
  }

  searchActivities(searchCriteria: SubmittedActivitySearch): Observable<SearchResult<SubmittedActivity>> {
    return this.apiRest.post<SearchResult<SubmittedActivity>>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/boost/activities/search`,
      searchCriteria
    );
  }

  getCreditsSummary(): Observable<CreditsSummary[]> {
    return this.apiRest.get<CreditsSummary[]>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/boost/performance/credits-summary`
    );
  }

  getTrainingStatuses(): Observable<Status[]> {
    return this.apiRest.get<Status[]>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/boost/trainings/statuses`
    );
  }

  getActivityStatuses(): Observable<Status[]> {
    return this.apiRest.get<Status[]>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/boost/activities/statuses`
    );
  }

  getCurrency(): Observable<Currency> {
    return this.apiRest.get<Currency>(`${environment.api.core.baseUrl}/members/users/currency`);
  }
}
