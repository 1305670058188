<div class="d-flex flex-column justify-content-center align-items-center" *ngIf="performance$ | async as performance">
  <h5 class="mb-1">
    <strong>{{ "Your company performance" | translate | uppercase }}</strong>
  </h5>
  <h6 *ngIf="performance.partnerType === UserPartnerType.MSP_PARTNER_TYPE">
    <strong>({{ 'Milestone devices achieved' | translate | uppercase }})</strong>
  </h6>
  <div
    class="d-flex"
    [ngClass]="{
      'mt-1': performance.partnerType === UserPartnerType.MSP_PARTNER_TYPE,
      'mt-4': performance.partnerType === UserPartnerType.OEM_PARTNER_TYPE
    }"
  >
    <div
      class="d-flex flex-column justify-content-center align-items-center performance-item"
      [ngClass]="{ 'ms-2': index > 0, 'ms-sm-3': index > 0, 'ms-lg-2': index > 0, 'ms-xl-4': index > 0 }"
      *ngFor="let value of performance.values; let index = index"
    >
      <img [src]="'assets/image/full-performance.png'" *ngIf="value.completed" />
      <img [src]="'assets/image/incomplete-performance.png'" *ngIf="!value.completed" />
      <div class="mt-1">{{ value.label }}</div>
    </div>
  </div>
  <div class="mt-3 w-100 d-flex justify-content-end">
    <h2>
      <strong>Lenovo {{ performance.partnerType }}</strong>
    </h2>
  </div>
</div>
