<div class="d-flex flex-column stats">
  <h4 class="offcanvas-body">{{ 'Shopping Cart' | translate }}</h4>

  <div class="offcanvas-body d-flex align-items-center table-header">
    <div class="w-50 pe-3">
      <strong>{{ 'Product' | translate }}</strong>
    </div>
    <div class="w-25">
      <strong>{{ 'Quantity' | translate }}</strong>
    </div>
    <div class="mx-1">
      <strong>{{ 'Price' | translate }}</strong>
    </div>
    <div class="flex-fill d-flex justify-content-end">
      <div class="px-1"></div>
    </div>
  </div>

  <div>
    <hr />
  </div>

  <ng-container *ngFor="let item of cart?.products">
    <div class="offcanvas-body d-flex">
      <div class="w-50 pe-3">
        {{ item.name }}
      </div>
      <div class="w-25">
        {{ item.quantity }}
      </div>
      <div class="mx-1">
        {{ item.points | number }}
      </div>
      <div class="flex-fill d-flex justify-content-end">
        <div>
          <button class="p-0 btn close-button" (click)="removeItem(item)">
            <fa-icon [icon]="['fal', 'xmark']" size="lg"></fa-icon>
          </button>
        </div>
      </div>
    </div>

    <div>
      <hr />
    </div>
  </ng-container>

  <div class="offcanvas-body d-flex align-items-center justify-content-between table-footer">
    <div *ngIf="(cart?.products?.length ?? 0) > 1">
      <strong>{{ cart?.products?.length }} {{ 'Items' | translate }}</strong>
    </div>
    <div *ngIf="(cart?.products?.length ?? 0) === 1">
      <strong>{{ cart?.products?.length }} {{ 'Item' | translate }}</strong>
    </div>

    <div class="me-2">
      <strong>{{ 'Subtotal' | translate }}: {{ cartTotal | number }}</strong>
    </div>
  </div>

  <div class="offcanvas-body" *ngIf="showWishlistButton">
    <app-custom-button
      [withIcon]="false"
      path="/reward-catalog/cart"
      [label]="'View Cart' | translate"
    ></app-custom-button>
    <app-custom-button
      type="outline"
      [withIcon]="false"
      path="/reward-catalog/wishlist"
      [label]="'View Wishlist' | translate"
    ></app-custom-button>
    <a class="p-0 btn btn-link" type="button" (click)="clearCart()">
      {{ 'Clear Cart' | translate }}
    </a>
  </div>
</div>
