import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { UserPartnerType } from 'src/app/core/enum/user-partner-type.enum';
import { Banner } from 'src/app/core/model/core/banner';
import { UserPerformance } from 'src/app/core/model/lenovo/user-performance';
import { BannerStoreService } from 'src/app/core/store/banner-store.service';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@UntilDestroy()
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  performance$ = this.userStore.performance$.pipe(filter(Boolean));
  performance: UserPerformance | null;
  currentPerformance: any;
  performanceCompletedLength = 0;
  banner: Banner;

  mspTrackerDesktopImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/msp-tracker-desktop.png';

  mspTrackerMobileImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/msp-tracker-mobile.png';

  oemTrackerDesktopImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/oem-tracker-desktop.png';

  oemTrackerMobileImage =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/Lenovo+Boost/Images/Refresh/oem-tracker-mobile.png';

  trackerImage: string;
  trackerMobileImage: string;

  // This figures control the width per bar of progress for desktop/tablet and mobile.
  // It's multiplied depending of the completed milestone below.
  mspBarPixel = 42.8;
  oemBarPixel = 33;
  mspBarMobilePixel = 55.6;
  oemBarMobilePixel = 42.5;

  barPixel = this.mspBarPixel;
  barMobilePixel = this.mspBarMobilePixel;
  barWidth = 0;
  barMobileWidth = 0;
  isOEM = false;

  constructor(private userStore: UserStoreService, private bannerStore: BannerStoreService) {}

  ngOnInit(): void {
    this.userStore.performance$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((data) => {
      this.performance = data;
      if (data.partnerType === UserPartnerType.MSP_PARTNER_TYPE) {
        this.trackerImage = this.mspTrackerDesktopImage;
        this.trackerMobileImage = this.mspTrackerMobileImage;
        this.barPixel = this.mspBarPixel;
        this.barMobilePixel = this.mspBarMobilePixel;
      } else {
        this.trackerImage = this.oemTrackerDesktopImage;
        this.trackerMobileImage = this.oemTrackerMobileImage;
        this.barPixel = this.oemBarPixel;
        this.barMobilePixel = this.oemBarMobilePixel;
        this.isOEM = true;
      }
      const performanceCompleted = data?.values.filter((value) => value.completed === true);
      this.performanceCompletedLength = performanceCompleted.length - 1;
      if (performanceCompleted) {
        this.barWidth = this.performanceCompletedLength * this.barPixel;
        this.barMobileWidth = this.performanceCompletedLength * this.barMobilePixel;
        this.currentPerformance = performanceCompleted.pop();
      }
    });

    this.bannerStore.banner$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((banner) => {
      this.banner = banner;
    });

    this.bannerStore.getBanner();
  }
}
